import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { matchPath } from 'react-router';
import { getConfig } from '../../config/portal';
// import { getCurrentRoutes as rts} from '../language';
import {
  generateSearchPath,
  isBDP,
  redirectBDPWrongUrls,
  getDefaultParams
} from './boats';
import { generateSearchPath as generateEngineSearchPath } from './engines';
import {
  geti18nLocations
} from '../../utils/language';
import { getPartyBrandedSRP } from './party';
import { getBoatUrl } from './boat';
import { getEngineUrl } from './engine';
import { SERVER_PATH_ACTIONS } from '../../constants/redirects';
import { generateBlogRedirectPath } from './blog';
import { getClassCategory } from '../classHelper';


import {
  isRegionFilterEnabled,
  isSubdivisionFilterEnabled
} from '../locationHelper';
import {
  locationIncompleteUrlRegion,
  locationIncompleteUrlSubdivision,
  locationIncompleteUrlCity
} from './location';
import { normalizeString } from '@dmm/lib-common/lib/formatting';
import fi from './parameterTranslations/fi.json';
import fr from './parameterTranslations/fr.json';
import de from './parameterTranslations/de.json';
import dk from './parameterTranslations/dk.json';
import it from './parameterTranslations/it.json';
import nl from './parameterTranslations/nl.json';
import es from './parameterTranslations/es.json';
import se from './parameterTranslations/se.json';
import {getDefaultI18Service, getFormatMessageFunction, getTPPServices} from '../../tppServices/tppDi';
import {getLanguageFromUrl} from '../../tppServices/translations/helpers';
import {getRouteConstantsFromI18n} from '../../tppServices/translations/constants';
import { getMessages } from '../../tppServices/translations/messages';

const urlTranslations = new Map();
urlTranslations.set('fi', fi);
urlTranslations.set('fr', fr);
urlTranslations.set('de', de);
urlTranslations.set('dk', dk);
urlTranslations.set('it', it);
urlTranslations.set('nl', nl);
urlTranslations.set('es', es);
urlTranslations.set('se', se);

const REDIRECT_FACET_NAMES = [
  'type',
  'condition',
  'class',
  'group',
  'make',
  'model',
  'id',
  'dealer',
  'bdp',
  'edp',
  'year',
  'fuel',
  'category',
  'country',
  'city',
  'region',
  'subdivision',
  'article',
  'category',
  'hullMaterial'
];

export const getMappedValue = (oldValue, facetMap = {}) => {
  const { valuesMap = {}, replacers = [], exceptions = [] } = facetMap;

  let newValue =
    valuesMap[oldValue] || valuesMap[oldValue] === ''
      ? valuesMap[oldValue]
      : oldValue;

  replacers.forEach(({ search, replacer }) => {
    if (!includes(exceptions, newValue)) {
      newValue = newValue
        .replace(new RegExp(search.join('|'), 'g'), replacer)
        .toLowerCase();
    }
  });

  return newValue;
};

const validateQueryString = ({ query }, queryString) => {
  if (!query) {
    return true;
  }

  const invalidQueryString = Object.entries(query).some(
    ([queryParam, queryValue]) =>
      !new RegExp(`(\\?|&)${queryParam}=${queryValue}(&|$)`).test(queryString)
  );

  return !invalidQueryString;
};

const getMappedArticleParams = (articleParams, mappedValues) => {
  const mappedArticleParams = {};

  if (!mappedValues || !articleParams) {
    return mappedArticleParams;
  }

  if (articleParams.includes('article')) {
    mappedArticleParams.article = mappedValues.article;
  }

  if (articleParams.includes('category')) {
    mappedArticleParams.category = mappedValues.category;
  }

  return mappedArticleParams;
};

const getMappedSearchParams = (searchParams, mappedValues) => {
  const mappedSearchParams = {};
  if (!mappedValues) {
    return mappedSearchParams;
  }
  if (searchParams.includes('multiFacetedBoatTypeClass')) {
    if (mappedValues.type) {
      mappedSearchParams.multiFacetedBoatTypeClass = {
        [mappedValues.type]: []
      };
      if (mappedValues.class) {
        mappedSearchParams.multiFacetedBoatTypeClass[mappedValues.type] = [
          mappedValues.class
        ];
      }
    }
  }
  if (searchParams.includes('makeModel')) {
    if (mappedValues.make) {
      mappedSearchParams.makeModel = { [mappedValues.make]: [] };
      if (mappedValues.model) {
        mappedSearchParams.makeModel[mappedValues.make] = [mappedValues.model];
      }
    }
  }
  if (searchParams.includes('condition')) {
    if (mappedValues.condition) {
      mappedSearchParams.condition = mappedValues.condition;
    }
  }
  // Location
  if (searchParams.includes('country')) {
    if (mappedValues.country) {
      mappedSearchParams.country = mappedValues.country;
    } else if (mappedValues.region) {
      //This logic is only used while we activate subdivisions on engines
      // eslint-disable-next-line no-unused-vars
      const [country, _] = mappedValues.region.split('.');
      mappedSearchParams.country = country;
    } else if (mappedValues.subdivision) {
      // eslint-disable-next-line no-unused-vars
      const [_, __, country] = mappedValues.subdivision.split('@');
      mappedSearchParams.country = country;
    } else if (mappedValues.city && mappedValues.city.includes('@')) {
      // eslint-disable-next-line no-unused-vars
      const [_, __, ___, country] = mappedValues.city.split('@');
      mappedSearchParams.country = country;
    }
  }
  if (searchParams.includes('region')) {
    if (mappedValues.region) {
      const [country, region] = mappedValues.region.split('.');
      mappedSearchParams.region = region;
      mappedSearchParams.country = mappedSearchParams.country || country;
    }
  }
  if (searchParams.includes('subdivision')) {
    if (mappedValues.subdivision) {
      const [subdivision, region, country] =
        mappedValues.subdivision.split('@');
      mappedSearchParams.subdivision = subdivision;
      mappedSearchParams.region = mappedSearchParams.region || region;
      mappedSearchParams.country = mappedSearchParams.country || country;
    }
  }
  if (searchParams.includes('city')) {
    if (mappedValues.city) {
      if (mappedValues.city.includes('@')) {
        const [city, subdivision, region, country] =
          mappedValues.city.split('@');
        mappedSearchParams.city = [city];
        mappedSearchParams.subdivision =
          mappedSearchParams.subdivision || subdivision;
        mappedSearchParams.region = mappedSearchParams.region || region;
        mappedSearchParams.country = mappedSearchParams.country || country;
      } else {
        mappedSearchParams.city = [mappedValues.city];
      }
    }
  }

  if (searchParams.includes('hullMaterial')) {
    if (mappedValues.hullMaterial) {
      mappedSearchParams.hullMaterial = [mappedValues.hullMaterial];
    }
  }
  if (searchParams.includes('fuel')) {
    if (mappedValues.fuel) {
      mappedSearchParams.fuel = mappedValues.fuel;
    }
  }
  // For engines
  if (searchParams.includes('category')) {
    if (mappedValues.category) {
      mappedSearchParams.category = mappedValues.category;
    }
  }
  if (searchParams.includes('type')) {
    mappedSearchParams.type = get(mappedValues, 'type');
  }
  if (searchParams.includes('group')) {
    mappedSearchParams.group = get(mappedValues, 'group');
  }
  return mappedSearchParams;
};

const redirectToLanguage = async (url, language) => {
  const {tpp} = getTPPServices();
  const localizedLocation = await geti18nLocations(tpp, url, null);
  for (const location of localizedLocation) {
    if (location.lang === language) {
      return location.route;
    }
  }
  return url;
};

export const sortWildcardFacetsPriority = (wildcards) => {
  const sortedWildcardFacets = Object.keys(wildcards || {});
  return sortedWildcardFacets.sort((a, b) => {
    if (wildcards[a].priority === wildcards[b].priority) {
      return 0;
    } else if (wildcards[a].priority === 'high') {
      return -1;
    }
    return 1;
  });
};

export const getMappedValues = (params, facetsMap = {}) => {
  const mappedValues = {};
  const { wildcards } = facetsMap;
  const facetsUsed = [];
  const sortedWildcardFacets = sortWildcardFacetsPriority(wildcards);

  REDIRECT_FACET_NAMES.forEach((facetName) => {
    if (params[facetName]) {
      mappedValues[facetName] = getMappedValue(
        params[facetName],
        facetsMap[facetName]
      );
    }
  });

  if (mappedValues.class && !mappedValues.type) {
    mappedValues.type = getMappedValue(params.class, facetsMap.type);
    if (
      mappedValues.type === params.class &&
      /power-|sail-|unpowered-/i.test(mappedValues.class)
    ) {
      mappedValues.type = mappedValues.class.split('-')[0];
    }
  }

  for (const wildcard in params) {
    const wildcardParam = params[wildcard];
    let paramFound = false;
    if (!wildcard.includes('wildcard') || !wildcardParam) {
      continue;
    }

    for (let i = 0; i < sortedWildcardFacets.length; i++) {
      if (facetsUsed.includes(sortedWildcardFacets[i])) {
        continue;
      }
      const valuesMap = wildcards[sortedWildcardFacets[i]].valuesMap;
      if (valuesMap[wildcardParam]) {
        mappedValues[sortedWildcardFacets[i]] = getMappedValue(
          params[wildcard],
          wildcards[sortedWildcardFacets[i]]
        );
        facetsUsed.push(sortedWildcardFacets[i]);
        paramFound = true;
        break;
      }
    }
    if (!paramFound) {
      mappedValues.model = getMappedValue(
        params[wildcard],
        get(wildcards, 'model')
      );
    }
  }

  if (params.makeModel && mappedValues.make) {
    const makeModel = params.makeModel.split('-').join(' ');
    mappedValues.model = makeModel
      .replace(mappedValues.make.toLowerCase(), '')
      .trim();
  }

  return mappedValues;
};

/**
 * Compares the lenght of two urls based on number of "/", preceeding shorter ones
 * @param { String } urlA The first Url to compare
 * @param { String } urlB The second Url to compare
 * @returns { Number }
 */
export const urlComparator = (urlA, urlB) => {
  if (urlA.split('/').length === urlB.split('/').length) {
    return 0;
  } else if (urlA.split('/').length > urlB.split('/').length) {
    return -1;
  }
  return 1;
};

/**
 * Compares two URLs based on the length of the origin URL, alphabetically.
 * Use the first element of the origin array.
 *
 * @returns location redirects map based on the origin URL lenght
 */
export const getLocationRedirects = () => {
  const locationRedirects = get(getConfig(), 'redirects.locationRedirects', []);
  return locationRedirects.sort((elementA, elementB) => {
    return urlComparator(elementA.origin[0], elementB.origin[0]);
  });
};

export const getRedirectsUrlPattern = () => {
  const redirectsMaps = get(getConfig(), 'redirects.redirectsMaps', []);
  return flatten(
    redirectsMaps.map(({ origins }) =>
      origins.map((urlPattern) => {
        return `${urlPattern.path || urlPattern}`;
      })
    )
  ).sort(urlComparator);
};

export const mapRedirectResultsToValues = (mappedValues, results) => {
  if (results.year) {
    mappedValues.year = results.year;
  }
  if (results.make) {
    mappedValues.make = results.make;
  }
  if (results.model) {
    mappedValues.model = results.model;
  }

  // Legacy-BDP to TPP-SRP redirect
  if (results.legacyId && results.make !== undefined) {
    mappedValues.search = true;
    // Remove model even if defined if make is not
    if (!results.make && results.model) {
      mappedValues.model = undefined;
    }
  }
};

export const getDestinationPath = async (
  redirectsMaps,
  path,
  search,
  mappedValues,
  data,
  i18nService
) => {
  if (!i18nService) {
     i18nService = getDefaultI18Service();
  }
  const { getCurrentI18n } = i18nService;
  const routeConstants = getRouteConstantsFromI18n(i18nService);
  const redirectMap = redirectsMaps.find((redirectMap) =>
    matchPath(path, { path: redirectMap.origins, exact: true })
  );
  const isLegacyBdpToSrp =
    redirectMap?.bdp && mappedValues?.search && !redirectMap?.lookupType;
  if (!redirectMap) {
    return;
  }

  const isQueryValid = validateQueryString(redirectMap, search);
  if (!isQueryValid) {
    return;
  }

  if (redirectMap.route) {
    let destPathname = routeConstants[redirectMap.route];
    if (redirectMap.language) {
      destPathname = await redirectToLanguage(destPathname, redirectMap.language);
    }
    return { destPathname };
  }
  if (redirectMap.pathRewrite && redirectMap.pathRewrite.length === 2) {
    return {
      destPathname:
        path.replace(redirectMap.pathRewrite[0], redirectMap.pathRewrite[1]) +
        (search ? search : '') +
        (redirectMap.appendToPath ? redirectMap.appendToPath : '')
    };
  }
  if (redirectMap.rootPathRewrite) {
    const rootPathMatch = redirectMap.rootPathRewrite.find((m) =>
      getCurrentI18n().host.includes(m.hostname)
    );
    if (rootPathMatch) {
      return {
        destPathname:
          path.replace(
            rootPathMatch.pathRewrite[0],
            rootPathMatch.pathRewrite[1]
          ) + (search ? search : '')
      };
    }
  }
  if (redirectMap.article) {
    return { destPathname: redirectMap.article };
  }
  if (redirectMap.articleParams) {
    const articleParams = getMappedArticleParams(
      redirectMap.articleParams,
      mappedValues
    );
    const articlePath = generateBlogRedirectPath({}, articleParams);
    return { destPathname: articlePath };
  }
  if (redirectMap.external) {
    return { destPathname: redirectMap.external, ext: true };
  }

  if (redirectMap.search || isLegacyBdpToSrp) {
    mappedValues &&
      Object.keys(mappedValues).forEach((key) => {
        mappedValues[key] =
          typeof mappedValues[key] === 'string' &&
          mappedValues[key].replace('-html', '');
      });
    const newSearch = Object.assign(
      {},
      isEmpty(redirectMap.search) ? mappedValues : redirectMap.search
    );
    let destPathname;
    if (newSearch.type === 'engines') {
      destPathname = generateEngineSearchPath({}, newSearch, true);
    } else {
      destPathname = generateSearchPath({}, newSearch, true);
    }
    if (redirectMap.language) {
      destPathname = await redirectToLanguage(destPathname, redirectMap.language);
    }
    return { destPathname };
  }
  if (redirectMap.dealer) {
    const normalizedName = mappedValues.dealer || data.dealer;
    const party = { id: data.destination, normalizedName };
    let destPathname = getPartyBrandedSRP(party, false, null);
    return { destPathname };
  }
  if (redirectMap.bdp) {
    mappedValues.id = data.destination;
    let destPathname = getBoatUrl(mappedValues);
    return { destPathname };
  }
  if (redirectMap.edp) {
    mappedValues.id = data.destination;
    let destPathname = getEngineUrl(mappedValues);
    return { destPathname };
  }
  if (redirectMap.errorCode) {
    return { errorCode: redirectMap.errorCode };
  }
  if (redirectMap.searchParams) {
    const searchParams = getMappedSearchParams(
      redirectMap.searchParams,
      mappedValues
    );
    let destPathname;
    if (searchParams.type === 'engines') {
      destPathname = generateEngineSearchPath({}, searchParams, true);
    } else {
      destPathname = generateSearchPath({}, searchParams, true);
    }
    return { destPathname };
  }
};

const replaceQueryParams = (req, paramMap) => {
  const params = req.query;
  let redirectPath = req.path;
  for (const paramConfig of paramMap) {
    const param = Object.keys(params).find(
      (q) => decodeURI(q) === decodeURI(paramConfig.mapFrom)
    );
    if (param) {
      redirectPath += `${paramConfig.mapTo}-${params[param].replace(
        paramConfig.find ? new RegExp(paramConfig.find, 'g') : '',
        paramConfig.replace ? paramConfig.replace : ''
      )}/`;
      delete req.query[paramConfig.mapFrom];
    }
  }
  return redirectPath;
};

const replacePathParamsWithQueryParams = (req, paramMap) => {
  const params = req.query;
  let redirectPath = req.path;
  for (const paramConfig of paramMap) {
    const param = Object.keys(params).find(
      (q) => decodeURI(q) === decodeURI(paramConfig.mapFrom)
    );
    if (param) {
      let queryParamValue = params[param];
      let pathParamValue =
        paramConfig.queryMap?.find((map) => queryParamValue === map.from)?.to ||
        queryParamValue;

      if (redirectPath.includes(paramConfig.mapTo)) {
        redirectPath = redirectPath.replace(paramConfig.mapTo, pathParamValue);
      } else {
        redirectPath += `${paramConfig.mapTo}-${pathParamValue}`;
      }
      delete req.query[paramConfig.mapFrom];
    }
  }
  return redirectPath;
};

const handleChildParams = (paramConfig, key, value, finalParams) => {
  const findAndReplace = paramConfig.find && paramConfig.replace;
  const values = value.split('+');
  const newKey = paramConfig.mapTo ? paramConfig.mapTo : key;
  finalParams[newKey] =
    `${finalParams[newKey] ? `${finalParams[newKey]}+` : ''}` +
    `${get(values, '[0]', '').replace(
      findAndReplace ? paramConfig.find : '',
      findAndReplace ? paramConfig.replace : ''
    )}`;
  values.shift();
  if (values.length > 0) {
    finalParams[paramConfig.children] =
      `${
        finalParams[paramConfig.children]
          ? `${finalParams[paramConfig.children]}+`
          : ''
      }` + `${values.length > 1 ? values.join('+') : values[0]}`;
  }
};

const handleParams = (pathParams, paramConfig, key, value, finalParams) => {
  const findAndReplace = paramConfig.find && paramConfig.replace;
  const values = value.indexOf('+') !== -1 ? value.split('+') : [value];
  const newKey =
    paramConfig.mapTo &&
    (!paramConfig.ignoreSingleParam ||
      (paramConfig.ignoreSingleParam &&
        (pathParams.filter((p) => p.startsWith(`${key}-`)).length > 1 ||
          values.length > 1)))
      ? paramConfig.mapTo
      : key;
  const keyValue = paramConfig.keyValuePairs ? values.shift() : '';
  finalParams[newKey] =
    `${finalParams[newKey] ? `${finalParams[newKey]}+` : ''}` +
    `${paramConfig.keyValuePairs && values.length > 0 ? `${keyValue}:` : ''}` +
    `${
      values.length > 1
        ? values
            .join(`+${paramConfig.keyValuePairs ? `${keyValue}:` : ''}`)
            .replace(
              findAndReplace ? paramConfig.find : '',
              findAndReplace ? paramConfig.replace : ''
            )
        : get(values, '[0]', '').replace(
            findAndReplace ? paramConfig.find : '',
            findAndReplace ? paramConfig.replace : ''
          )
    }${paramConfig.keyValuePairs && values.length === 0 ? keyValue : ''}`;
};

const consolidateParams = (req, paramMap, excludePaths) => {
  if (excludePaths && excludePaths.find((e) => req.path.includes(e))) {
    return req.path;
  }
  const pathParams = req.path.split('/');
  let finalParamArray = [pathParams[1]];
  pathParams.shift();
  pathParams.shift();
  if (req.path.endsWith('/')) {
    pathParams.pop();
  }
  const finalParams = {};
  for (const param of pathParams) {
    const key = param.substring(0, param.indexOf('-'));
    const value = param.substring(param.indexOf('-') + 1);
    if (!key || !value) {
      continue;
    }

    const paramConfig = paramMap.find(
      (p) => decodeURI(p.mapFrom) === decodeURI(key)
    );
    if (
      paramConfig &&
      (!paramConfig.skipWhenParamPresent ||
        pathParams.filter((p) =>
          paramConfig.skipWhenParamPresent.includes(
            p.substring(0, p.indexOf('-'))
          )
        ).length === 0)
    ) {
      const paramRegex = new RegExp(paramConfig.ignorePattern);
      const matchedParam = paramRegex.test(param);
      if (!paramConfig.ignorePattern || !matchedParam) {
        if (paramConfig.children) {
          handleChildParams(paramConfig, key, value, finalParams);
        } else {
          handleParams(pathParams, paramConfig, key, value, finalParams);
        }
      } else {
        finalParams[key] = value;
      }
    } else {
      finalParams[key] = value;
    }
  }
  for (const key of Object.keys(finalParams)) {
    finalParamArray.push(`${key}-${finalParams[key]}`);
  }
  return `/${finalParamArray.join('/')}/`;
};

export const matchPrefixPaths = (prefixPaths, redirectPath) => {
  let matched = false;

  if (prefixPaths && prefixPaths.length) {
    for (const prefixPath of prefixPaths) {
      if ([0, 3].includes(redirectPath.indexOf(prefixPath))) {
        matched = true;
        break;
      }
    }
  }

  return matched;
};

export const getBDPPath = (req, i18Service) => {
  const messages = getMessages();
  const { language } = getLanguageFromUrl(
    req.url,
    `${req.protocol}://${req.hostname}`
  );
  const formatMessage = getFormatMessageFunction(i18Service, language);
  const bdpRootPath = formatMessage(messages.detailsRoot, undefined, language);
  let swapSbp = req.path.replace('/sbp/', `/${bdpRootPath}/`);
  swapSbp = swapSbp.endsWith('/') ? swapSbp : `${swapSbp}/`;
  return { redirectPath: swapSbp };
};

export const reorderParams = (req, paramOrder, excludePaths) => {
  if (excludePaths && excludePaths.find((e) => req.path.includes(e))) {
    return req.path;
  }
  const pathParams = req.path.split('/');
  let finalParamArray = [pathParams[1]];
  pathParams.shift();
  pathParams.shift();
  if (req.path.endsWith('/')) {
    pathParams.pop();
  }
  for (const param of paramOrder) {
    const paramMatch = pathParams.find((p) =>
      decodeURI(p).startsWith(`${param}-`)
    );
    if (paramMatch) {
      finalParamArray.push(paramMatch);
    }
  }
  return `/${finalParamArray.join('/')}/`;
};

const escapeRegexChars = (pattern) =>
  pattern.replace(/([\^$.|?*+()[\]{}])/g, '\\$1');

export const cleanParams = (req, paramMap, excludePaths) => {
  if (excludePaths && excludePaths.find((e) => req.path.includes(e))) {
    return req.path;
  }
  const pathParams = req.path.split('/');
  let finalParamArray = [pathParams[1]];
  pathParams.shift();
  pathParams.shift();
  if (req.path.endsWith('/')) {
    pathParams.pop();
  }
  for (const pathParam of pathParams) {
    let decodeParam = decodeURI(pathParam);
    const paramMatch = paramMap.find((p) =>
      decodeParam.startsWith(`${decodeURI(p.name)}-`)
    );
    const removePattern = get(paramMatch, 'removeWhenPresent', undefined);
    const removeRegex = removePattern
      ? new RegExp(removePattern, 'g')
      : undefined;
    const removePatterns = removeRegex
      ? decodeURI(req.path).match(removeRegex)
      : undefined;
    const ignorePatternWithPrefix = get(
      paramMatch,
      'ignorePatternPrefix',
      undefined
    )
      ? `${paramMatch.ignorePatternPrefix}${pathParam}`
      : undefined;
    const ignoreRegex = ignorePatternWithPrefix
      ? new RegExp(ignorePatternWithPrefix, 'g')
      : undefined;
    const ignorePatterns = ignoreRegex
      ? req.path.match(ignoreRegex)
      : undefined;
    if (get(paramMatch, 'translate', undefined)) {
      let paramValue = decodeParam.replace(
        new RegExp(`${paramMatch.name}-`, 'g'),
        ''
      );
      let translatedValue = get(
        urlTranslations.get(paramMatch.locale),
        [paramMatch.name, 'values', paramValue],
        undefined
      );
      if (translatedValue) {
        paramValue = escapeRegexChars(paramValue);
        decodeParam = decodeParam.replace(
          new RegExp(paramValue, 'g'),
          translatedValue
        );
      }
    }
    if (ignorePatterns || !paramMatch) {
      finalParamArray.push(decodeParam);
    } else if (paramMatch && !removePatterns && !ignorePatterns) {
      if (paramMatch.rename) {
        finalParamArray.push(
          decodeParam.replace(
            new RegExp(paramMatch.name, 'g'),
            paramMatch.rename
          )
        );
      } else if (paramMatch.replace) {
        finalParamArray.push(
          decodeParam.replace(
            new RegExp(paramMatch.find, 'g'),
            paramMatch.replace
          )
        );
      } else {
        finalParamArray.push(decodeParam);
      }
    }
  }
  return `/${finalParamArray.join('/')}/`;
};

export const arePathsDifferent = (redirectPath, requestPath) => {
  if (!redirectPath.endsWith('/')) {
    redirectPath += '/';
  }

  if (!requestPath.endsWith('/')) {
    requestPath += '/';
  }

  return decodeURI(redirectPath) !== decodeURI(requestPath);
};

export const getServerPathActionsForRedirects = (req) => {
  const serverPathActions = get(
    getConfig(),
    'redirects.serverPathActions',
    false
  );
  const requestPath = req.url;
  if (serverPathActions) {
    let redirectPath = decodeURI(`${requestPath}`);
    let redirectCode = 301;

    const { language } = getLanguageFromUrl(
      requestPath,
      `${req.protocol}://${req.hostname}`
    );

    for (const serverPathAction of serverPathActions) {
      const {
        action,
        pathPrefix,
        matchPattern,
        matchFlags,
        paramMap,
        paramOrder,
        excludePaths,
        removeChildren,
        removePattern
      } = serverPathAction;
      const matchedPrefixPath = matchPrefixPaths(pathPrefix, redirectPath);
      if (!matchedPrefixPath) {
        continue;
      }

      const regex = new RegExp(matchPattern, matchFlags);
      const matchedPatterns = redirectPath.match(regex);
      if (matchedPatterns) {
        switch (action) {
          case SERVER_PATH_ACTIONS.REMOVE:
            redirectPath = redirectPath.replace(regex, '');
            break;
          case SERVER_PATH_ACTIONS.REPLACE:
            redirectPath = redirectPath.replace(
              regex,
              serverPathAction.replace
            );
            break;
          case SERVER_PATH_ACTIONS.SELECT_FIRST:
            if (removePattern) {
              const removeRegex = new RegExp(
                `${matchPattern}${removePattern}`,
                matchFlags
              );
              const removePatterns = redirectPath.match(removeRegex);
              if (removePatterns) {
                for (const removePattern of removePatterns) {
                  var removeArray = removePattern.substring(1).split('/');
                  removeArray.shift();
                  const removeString = removeArray.join('/') + '/';
                  redirectPath = redirectPath.replace(removeString, '');
                }
              }
            }
            if (matchedPatterns.length > 1) {
              for (let i = matchedPatterns.length - 1; i > 0; i--) {
                if (removeChildren) {
                  var changed = false;
                  do {
                    changed = false;
                    for (const childPattern of removeChildren) {
                      const matchedChildren = redirectPath.match(
                        new RegExp(
                          `${matchedPatterns[i]}${childPattern}`,
                          matchFlags
                        )
                      );
                      if (matchedChildren) {
                        for (const matchedChild of matchedChildren) {
                          var childArray = matchedChild.substring(1).split('/');
                          childArray.shift();
                          const childString = childArray.join('/') + '/';
                          redirectPath = redirectPath.replace(childString, '');
                          changed = true;
                        }
                      }
                    }
                  } while (changed);
                }
                redirectPath = redirectPath.replace(matchedPatterns[i], '');
              }
            }
            break;
          case SERVER_PATH_ACTIONS.CONSOLIDATE_PARAMS:
            redirectPath = consolidateParams(req, paramMap, excludePaths);
            break;
          case SERVER_PATH_ACTIONS.REDIRECT:
            if (serverPathAction.language && serverPathAction.language !== language) {
              break;
            }
            redirectPath = `${serverPathAction.pathPrefix[0]}${serverPathAction.redirectURL}`;
            break;
          case SERVER_PATH_ACTIONS.REDIRECT_MANY:
            for (let replacer of serverPathAction.replacers) {
              if (requestPath.includes(replacer.origin)) {
                redirectPath = requestPath.replace(
                  replacer.origin,
                  replacer.destination
                );
                break;
              }
            }
            break;
          default:
            break;
        }
      }
      if (req.query) {
        switch (action) {
          case SERVER_PATH_ACTIONS.REPLACE_QUERY_PARAMS:
            redirectPath = replaceQueryParams(req, paramMap);
            break;
          case SERVER_PATH_ACTIONS.REPLACE_PATH_PARAM_WITH_QUERY_PARAMS:
            redirectPath = replacePathParamsWithQueryParams(req, paramMap);
            break;
          default:
            break;
        }
      }
      if (action === SERVER_PATH_ACTIONS.CLEAN_PARAMS) {
        redirectPath = cleanParams(req, paramMap, excludePaths);
      }
      if (action === SERVER_PATH_ACTIONS.REORDER_PARAMS) {
        redirectPath = reorderParams(req, paramOrder, excludePaths);
      }
      // optionally set status code
      if (serverPathAction.statusCode) {
        redirectCode = serverPathAction.statusCode;
      }
      if (!isEmpty(req.query) && !redirectPath.includes('?')) {
        const queryParams = Object.entries(req.query).map(
          ([key, value]) => `${key}=${value}`
        );
        redirectPath += `?${
          queryParams.length > 1 ? queryParams.join('&') : queryParams[0]
        }`;
      }
      if (arePathsDifferent(redirectPath, requestPath)) {
        break;
      }
    }

    if (isBDP(redirectPath, language)) {
      ({ redirectPath, redirectCode } = redirectBDPWrongUrls(
        redirectPath,
        redirectCode,
        language
      ));
    }

    return {
      redirect: arePathsDifferent(redirectPath, requestPath),
      redirectCode,
      redirectPath
    };
  }

  return {
    redirect: false
  };
};

export const getFinalServerPathActionsForRedirects = (req) => {
  const originalPath = req.path;
  const lastRedirect = { count: 0 };
  const maxRedirects = get(getConfig(), 'redirects.maxRedirects', 20);

  let currentReq = req;
  while (lastRedirect.count < maxRedirects) {
    const { redirect, redirectCode, redirectPath } =
      getServerPathActionsForRedirects(currentReq);
    if (redirect) {
      lastRedirect.code = redirectCode;
      lastRedirect.path = redirectPath;
      lastRedirect.count++;
      currentReq = { ...currentReq, url: redirectPath, path: redirectPath };
    } else {
      return {
        redirect: lastRedirect.count > 0,
        redirectCode: lastRedirect.code || redirectCode,
        redirectPath: lastRedirect.path || redirectPath,
        ...(lastRedirect.count > 0 && { redirectCount: lastRedirect.count })
      };
    }
  }

  // eslint-disable-next-line no-console
  console.warn(
    `Max redirects of ${maxRedirects} exceeded for path: ${originalPath}`
  );
  return {
    redirect: false
  };
};
export const getUntranslatedFunctions = (i18Service) => {
  if (!i18Service) {
    i18Service = getDefaultI18Service();
  }
  const getUntranslatedItem = i18Service.getUntranslatedItem;
  const getUntranslatedClass = (urlClass) => {
    const pathMatcher = 'routes.search.item.class.';
    return getUntranslatedItem(pathMatcher, urlClass);
  };

  const getUntranslatedType = (urlType) => {
    const pathMatcher = 'routes.search.item.types.';
    return getUntranslatedItem(pathMatcher, urlType);
  };

  const getUntranslatedHullMaterial = (urlHull) => {
    const pathMatcher = 'routes.search.item.hull-material.';
    return getUntranslatedItem(pathMatcher, urlHull);
  };

  const getUntranslatedFuelType = (urlFuel) => {
    const pathMatcher = 'routes.search.item.fuel-type.';
    return getUntranslatedItem(pathMatcher, urlFuel);
  };

  const getUntranslatedCountry = (urlCountry) => {
    const pathMatcher = 'app.countries.';
    return getUntranslatedItem(pathMatcher, urlCountry);
  };

  const getUntranslatedRegion = (urlRegion) => {
    const pathMatcher = 'app.countryRegions.';
    return getUntranslatedItem(pathMatcher, urlRegion);
  };

  const getUntranslatedSubdivision = (urlSubdivision) => {
    const pathMatcher = 'app.countrySubdivision.';
    return getUntranslatedItem(pathMatcher, urlSubdivision);
  };
  return { getUntranslatedClass, getUntranslatedType, getUntranslatedHullMaterial, getUntranslatedFuelType, getUntranslatedCountry, getUntranslatedRegion, getUntranslatedSubdivision };
};

export const typeClassRedirectUrl = (urlMatchParams, i18Service = getDefaultI18Service()) => {
  const messages = getMessages();
  const t = getFormatMessageFunction(i18Service);
  if (urlMatchParams?.classes && !urlMatchParams?.types) {
    const { getUntranslatedClass } = getUntranslatedFunctions(i18Service);
    const untranslatedClass = getUntranslatedClass(urlMatchParams.classes);
    const type = getClassCategory(untranslatedClass);
    const translatedCategorySufix = t(messages.search.types);
    const translatedCategory = messages.typesFacet[type]
      ? t(messages.typesFacet[type])
      : undefined;
    const urlCategory = translatedCategory
      ? translatedCategorySufix + '-' + translatedCategory
      : '';
    const update = getDefaultParams({
      types: urlCategory,
      classes: urlMatchParams.classes
    });
    return generateSearchPath(update, getDefaultParams(urlMatchParams), true);
  }
  return '';
};

export const makeModelRedirectUrl = (urlMatchParams) => {
  if (!urlMatchParams?.make && urlMatchParams?.model) {
    return generateSearchPath({}, getDefaultParams({}), true);
  }
  return '';
};

export const locationRedirectUrl = (
  locatedListing,
  props,
  currentMatchParams
) => {
  const listingLocation = get(locatedListing, 'location.address', {});
  let url = get(props, 'match.url');
  if (!url.endsWith('/')) {
    url = `${url}/`;
  }

  let rewrittenURL = url;
  if (!(listingLocation?.country)) {
    return '';
  }
  if (isRegionFilterEnabled(listingLocation.country)) {
    rewrittenURL = locationIncompleteUrlRegion(
      currentMatchParams,
      rewrittenURL,
      listingLocation
    );
  } else if (isSubdivisionFilterEnabled(listingLocation.country)) {
    rewrittenURL = locationIncompleteUrlSubdivision(
      currentMatchParams,
      rewrittenURL,
      listingLocation
    );
  } else if (!currentMatchParams.country) {
    rewrittenURL = locationIncompleteUrlCity(
      currentMatchParams,
      listingLocation
    );
  }
  return rewrittenURL === url ? '' : rewrittenURL;
};

export const nonExpectedUrlsRedirect = (urlMatchParams, facets, i18Service) => {
  const {
    getUntranslatedType,
    getUntranslatedClass,
    getUntranslatedCountry,
    getUntranslatedRegion,
    getUntranslatedSubdivision,
    getUntranslatedHullMaterial,
    getUntranslatedFuelType
  } = getUntranslatedFunctions(i18Service);
  if (urlMatchParams?.types) {
    if (urlMatchParams.types.includes('+')) {
      return '';
    }
    const type = getUntranslatedType(urlMatchParams.types);
    if (!type) {
      return generateSearchPath(undefined, undefined, true);
    }
    if (urlMatchParams.classes) {
      if (urlMatchParams.classes.includes('+')) {
        return '';
      }

      const className = getUntranslatedClass(urlMatchParams.classes);
      if (!className) {
        return generateSearchPath({
          multiFacetedBoatTypeClass: { [type]: [] }
        });
      }
    }
  }

  if (urlMatchParams?.country) {
    const country = getUntranslatedCountry(urlMatchParams.country);
    if (!country) {
      return generateSearchPath(undefined, undefined, true);
    }
    if (urlMatchParams?.region) {
      const region = getUntranslatedRegion(urlMatchParams.region);
      if (!region) {
        return generateSearchPath(
          { country: normalizeString(country) },
          undefined,
          true
        );
      }
    }
    if (urlMatchParams?.subdivision) {
      const subdivision = getUntranslatedSubdivision(
        urlMatchParams.subdivision
      );
      if (!subdivision) {
        return generateSearchPath(
          { country: normalizeString(country) },
          undefined,
          true
        );
      }
    }
  }

  if (urlMatchParams?.hullMaterial) {
    if (urlMatchParams.hullMaterial.includes('+')) {
      return '';
    }
    const hullMaterial = getUntranslatedHullMaterial(
      urlMatchParams.hullMaterial
    );
    if (!hullMaterial) {
      return generateSearchPath(undefined, undefined, true);
    }
  }

  if (urlMatchParams?.fuelType) {
    if (urlMatchParams.fuelType.includes('+')) {
      return '';
    }
    const fuelType = getUntranslatedFuelType(urlMatchParams.fuelType);
    if (!fuelType) {
      return generateSearchPath(undefined, undefined, true);
    }
  }

  if (urlMatchParams?.make) {
    if (isEmpty(facets)) {
      return '';
    }

    const make = urlMatchParams.make.split('-').slice(1).join('-');
    if (!facets.make) {
      return generateSearchPath({ makeModel: { [make]: [] } });
    }
    if (
      !facets.make.find(
        (makeFacet) => normalizeString(makeFacet.value) === make
      )
    ) {
      return generateSearchPath(undefined, undefined, true);
    }
  }
  return '';
};
